import axios from '..//utils/axios';
import { joinPaths } from '../utils/url_utility';

export default class DirectoryService {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    /**
     * returns all people marked for kiosk
     * @param {string} search
     */
    getAll() {
        return axios.get(this.endpoint);
    }

    /**
     * Return a specific person
     * @param {number} personId 901359
     */
    getById(personId) {
        return axios.get(joinPaths(this.endpoint, personId));
    }
}