<template>
    <g>
        <path :class="backClass" d="M177.053,255h96.188v91H177.053V255Z" transform="translate(-173.5 -251.5)" />
        <path :class="foreClass" d="M545.555,276.879v-7.336H516.944V278.9h14.83l-12.508,11.454,2.022,5.165a24.238,24.238,0,0,1,5.992-.6c5.243,0,7.714,2.246,7.714,4.941,0,3.368-3.37,5.09-7.564,5.09a26.516,26.516,0,0,1-9.362-1.647l-2.322,9.133a38.3,38.3,0,0,0,12.657,2.021c10.935,0,17.9-7.56,17.9-15.046,0-6.139-3.445-11.229-13.257-11.978Z" transform="translate(-477.5 -251.5)" />
        <path :class="foreClass" d="M499.95,328.81v-2.5h-9.776v2.5H493.7v9.242h2.725V328.81h3.526Zm8.4,9.242v-4.773a2.052,2.052,0,0,0-2.163-2.306,3.381,3.381,0,0,0-2.549,1.329v-5.991h-2.371v11.741h2.387v-4.276a2.22,2.22,0,0,1,1.571-.833c0.465,0,.737.208,0.737,0.881v4.228h2.388Zm4.246-9.354a1.33,1.33,0,1,0-1.33,1.33A1.34,1.34,0,0,0,512.592,328.7Zm-0.144,9.354V331.1H510.06v6.951h2.388Zm6.714-4.709v-2.37a2.686,2.686,0,0,0-2.484,1.249V331.1h-2.323v6.951h2.387v-4.388C517.127,333.135,518.025,333.1,519.162,333.343Zm8.093,4.709V326.311h-2.372v5.206a2.512,2.512,0,0,0-1.731-.544,3.529,3.529,0,0,0-3.317,3.6c0,2.034,1.25,3.6,3.173,3.6a2.615,2.615,0,0,0,1.939-.7v0.576h2.308Zm-2.372-2.37a1.429,1.429,0,0,1-1.266.657c-0.913,0-1.41-.657-1.41-1.794a1.458,1.458,0,0,1,1.442-1.73,1.618,1.618,0,0,1,1.234.688v2.179Zm15.207-6.872v-2.5h-7.436v11.741h2.725v-4.26h4.038v-2.387h-4.038V328.81h4.711Zm3.734,9.242V326.311h-2.372v11.741h2.372Zm8.91-3.475a3.745,3.745,0,1,0-3.734,3.6A3.517,3.517,0,0,0,552.734,334.577Zm-2.356,0c0,1.169-.513,1.842-1.378,1.842-0.882,0-1.395-.673-1.395-1.842s0.513-1.842,1.395-1.842C549.865,332.735,550.378,333.407,550.378,334.577Zm10.865,0a3.745,3.745,0,1,0-3.735,3.6A3.517,3.517,0,0,0,561.243,334.577Zm-2.356,0c0,1.169-.513,1.842-1.379,1.842-0.881,0-1.394-.673-1.394-1.842s0.513-1.842,1.394-1.842C558.374,332.735,558.887,333.407,558.887,334.577Zm8.589-1.234v-2.37a2.686,2.686,0,0,0-2.484,1.249V331.1h-2.324v6.951h2.388v-4.388C565.441,333.135,566.338,333.1,567.476,333.343Z" transform="translate(-477.5 -251.5)" />
    </g>
</template>
<script>
    export default {
        props: {
            foreClass: {
                type: String,
                required: false,
                default: ''
            },
            backClass: {
                type: String,
                required: false,
                default: ''
            }
        }
    }
</script>
