<template>
        <div>
            <div class="d-flex flex-wrap">
                <div v-for="person in personsWithPhotos"
                     :key="person.id"
                     class="photo-container">
                    <a :href="'#' + person.id"
                       @click="personClick(person.id)">
                        <img :src="person.photo.tablet" class="headshot" />
                    </a>
                    <div class="text-block">
                        {{ person.displayName }}
                    </div>
                </div>
            </div>
        </div>
    
</template>

<script>
    export default {
        name: 'PhotoList',
        props: {
            persons: {
                type: Array,
                required: true
            }
        },
        data() {
            return {

            }
        },
        created() {

        },
        computed: {
            personsWithPhotos() {
                return this.persons.filter(p => p.photo != null && !p.isPhotoPrivate);
            }
        },
        methods: {
            personClick(personId) {
                this.$emit('person-select', personId);
            }
        }
    }
</script>
<style lang="scss">
    $photoWidth: 237px;
    $photoHeight: 296px;
    $textBlockFromEdge: 15px;

    .headshot {
        object-fit: cover;
        width: $photoWidth;
        height: $photoHeight;
    }
    .photo-container {
        position: relative;
    }

    .text-block {
        position: absolute;
        bottom: 15px;
        left: $textBlockFromEdge;
        width: $photoWidth - ($textBlockFromEdge * 2);
        text-align: center;
        background-color: rgba(0,0,0,0.6);
        color: white;
        padding-left: 20px;
        padding-right: 20px;
        font-size:1.2rem;
    }
</style>