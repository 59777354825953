<template>
    <table class="table names">
        <thead>
            <tr>
                <th>Name</th>
                <th>Office</th>
                <th>Phone</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="person in persons"
                :key="person.id"
                @click="personClick(person.id)">
                <td>{{ person.lastName }}, {{ person.firstName }}</td>
                <td>{{ officeAddress(person) }}</td>
                <td>{{ person.officePhone }}</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
    export default {
        name: 'NameList',
        props: {
            persons: {
                type: Array,
                required: true
            }
        },
        methods: {
            officeAddress(person) {
                if (person.officeAddress != null)
                    return person.officeAddress.addressLine1

                return '';
            },
            personClick(personId) {
                this.$emit('person-select', personId);
            }
        }
    }
</script>
<style>
    .names {
        font-size:2rem;
    }
    
</style>
