<template>
    <g>
        <path :class="backClass" d="M177.053,255h96.188v91H177.053V255Z" transform="translate(-173.5 -251.5)" />
        <path :class="foreClass" d="M704.092,304.727v-8.534h-5.917V268.72h-6.89l-22.469,28.446v7.561h18.8v9.132h10.56v-9.132h5.917Zm-16.477-8.534h-7.64l7.64-9.732v9.732Z" transform="translate(-631.5 -251.5)" />
        <path :class="foreClass" d="M652.185,329.379v-2.4H645.04v11.281h2.618v-4.094h3.881v-2.293h-3.881v-2.493h4.527Zm7.5,5.54a3.6,3.6,0,1,0-3.588,3.463A3.38,3.38,0,0,0,659.684,334.919Zm-2.264,0c0,1.124-.493,1.77-1.324,1.77-0.847,0-1.34-.646-1.34-1.77s0.493-1.77,1.34-1.77C656.927,333.149,657.42,333.8,657.42,334.919Zm10.378,3.34v-6.68H665.5v4.156a1.971,1.971,0,0,1-1.462.754,0.7,0.7,0,0,1-.755-0.846v-4.064h-2.294v4.6a2,2,0,0,0,2.125,2.2,3.637,3.637,0,0,0,2.448-1.247v1.124H667.8Zm6.22-4.525v-2.278a2.582,2.582,0,0,0-2.387,1.2v-1.078H669.4v6.68h2.295v-4.217C672.062,333.534,672.925,333.5,674.018,333.734Zm5.774-.354v-1.8h-1.771v-2.324l-2.279,1.309v1.015l-1.463,1.478v0.323h1.463V335.7c0,2.109.924,2.678,2.233,2.678a7.556,7.556,0,0,0,1.725-.246l-0.524-1.709a2.248,2.248,0,0,1-.585.108c-0.354,0-.57-0.2-0.57-0.769V333.38h1.771Zm8.037,4.879v-4.586a1.972,1.972,0,0,0-2.078-2.217,3.25,3.25,0,0,0-2.449,1.278v-5.756h-2.279v11.281h2.295V334.15a2.134,2.134,0,0,1,1.509-.8c0.446,0,.708.2,0.708,0.847v4.063h2.294Zm12.226-8.88v-2.4H692.91v11.281h2.617v-4.094h3.881v-2.293h-3.881v-2.493h4.528Zm3.587,8.88V326.978h-2.279v11.281h2.279Zm8.561-3.34a3.6,3.6,0,1,0-3.588,3.463A3.38,3.38,0,0,0,712.2,334.919Zm-2.264,0c0,1.124-.493,1.77-1.324,1.77-0.847,0-1.34-.646-1.34-1.77s0.493-1.77,1.34-1.77C709.446,333.149,709.939,333.8,709.939,334.919Zm10.44,0a3.6,3.6,0,1,0-3.588,3.463A3.38,3.38,0,0,0,720.379,334.919Zm-2.264,0c0,1.124-.493,1.77-1.324,1.77-0.847,0-1.34-.646-1.34-1.77s0.493-1.77,1.34-1.77C717.622,333.149,718.115,333.8,718.115,334.919Zm8.253-1.185v-2.278a2.582,2.582,0,0,0-2.387,1.2v-1.078h-2.233v6.68h2.295v-4.217C724.412,333.534,725.274,333.5,726.368,333.734Z" transform="translate(-631.5 -251.5)" />
    </g>
</template>
<script>
    export default {
        props: {
            foreClass: {
                type: String,
                required: false,
                default: ''
            },
            backClass: {
                type: String,
                required: false,
                default: ''
            }
        }
    }
</script>
