// taken from Gitlab
// https://gitlab.com/gitlab-org/gitlab/-/blob/master/app/assets/javascripts/lib/utils/axios_utils.js

import axios from 'axios';
import errorResponseHandler from './errorHandler';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// apply interceptor on response
axios.interceptors.response.use(
    response => response,
    errorResponseHandler
);



export default axios;