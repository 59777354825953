import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/uw-fonts.0.0.1.css';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueModal } from '@aae/vue-modal';

Vue.component('vue-modal', VueModal);


Vue.config.productionTip = false



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
