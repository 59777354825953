<template>
    <main id="app">
        <div class="row g-0 pb-2">
            <div class="col-3 logo ps-4">
                <router-link to="/">
                    <taylor-hall-svg/>
                </router-link>
            </div>
            <div class="col">
                <div class="row">
                    <div class="offset-1 col-4 text-center">
                        <router-link to="/directory" class="menu-button">
                            <directory-svg /><br />
                            Directory
                        </router-link>
                    </div>
                    <div class="col-4 text-center">
                        <router-link to="/map" class="menu-button">
                            <map-svg /><br />
                            Map
                        </router-link>
                    </div>
                </div>
                
       
            </div>
            <div class="col addresses">
                <div>
                    <span class="dept">Agricultural &amp; Applied Economics</span> - Rm 429
                </div>
                <div>
                    <span class="dept">UW Center for Cooperatives</span> - Rm 235
                </div>
                <div>
                    <span class="dept">Center for Dairy Profitability</span> - Rm 203
                </div>

            </div>
        </div>

        <router-view />
    </main>
</template>
<script>
    import TaylorHallSvg from './assets/taylor-hall.svg';
    import DirectorySvg from './assets/directory.svg';
    import MapSvg from './assets/map.svg';
    import DirectoryService from './services/DirectoryService';

    export default {
        name: 'App',
        components: {
            TaylorHallSvg,
            DirectorySvg,
            MapSvg

        },
        provide: {
            directoryService: new DirectoryService('/api/directory')
        }
    }
</script>
<style>
    body {
        background-color: #c5050c;
        font-family: "Verlag", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
        color: #0a0a0a;
        overflow: hidden;
    }
    #app {
        padding-top: 2rem;

    }
    /**
        Taylor Hall logo
           */
    .cls-12 {
        fill: #fff;

    }
    .menu-button {
        fill: #fff;
        font-size:20px;
        color: #fff;
        text-decoration:none;
    }
    a.menu-button:hover {
        color: #258faf;
    }
    .menu-button svg {
        width: 5rem;
        height:5rem;
    }
    .addresses {
        color: #fff;
        font-size: 1.6rem;
    }

        .addresses .dept {
            font-weight: 600;
        }

</style>
