<template>
    <div>
        <div class="row p-3 g-0">
            <div class="col">
                <floor-buttons @floor-click="floorClick"
                               :selected-floor="selectedFloor"></floor-buttons>
            </div>

        </div>
        <div class="map-holder pb-4">
            <img :src="mapImg" />
        </div>
    </div>
</template>
<script>
    import FloorButtons from '@/components/FloorButtons.vue';
    import Basement from '../assets/maps/Basement.png';
    import FirstFloor from '../assets/maps/1st_Floor.png';
    import SecondFloor from '../assets/maps/2nd_Floor.png';
    import ThirdFloor from '../assets/maps/3rd_Floor.png';
    import FourthFloor from '../assets/maps/4th_Floor.png';
    import FifthFloor from '../assets/maps/5th_Floor.png';

    export default {
        name: 'Map',
        components: {
            FloorButtons
        },
        props: {
        },
        data() {
            return {
                mapImg: FirstFloor,
                selectedFloor: '1'
            }
        },
        methods: {
            floorClick(floor) {
                this.selectedFloor = floor;
                switch (floor) {
                    case 'b':
                        this.mapImg = Basement;
                        break;
                    case '1':
                        this.mapImg = FirstFloor;
                        break;
                    case '2':
                        this.mapImg = SecondFloor;
                        break;
                    case '3':
                        this.mapImg = ThirdFloor;
                        break;
                    case '4':
                        this.mapImg = FourthFloor;
                        break;
                    case '5':
                        this.mapImg = FifthFloor;
                        break;
                }
            }
        }
    }
</script>
<style>
    .map-holder {
        background-color: #f1f1f1;
    }

        .map-holder img {
            max-width: 100%;
            height: auto;
        }
</style>
