<template>
    <g>
        <path :class="backClass" d="M177.053,255h96.188v91H177.053V255Z" transform="translate(-173.5 -251.5)" />
        <path :class="foreClass" d="M849.6,298.663c0-7.635-4.494-13.549-15.354-13.549-0.974,0-2.322.075-3.6,0.149V278.9H846.9v-9.357H820.235v24.7l0.674,0.824a36.43,36.43,0,0,1,8.538-.973c6.517,0,9.213,1.946,9.213,5.464,0,3.07-3.221,5.39-9.213,5.39a26.82,26.82,0,0,1-9.437-1.871l-1.572,9.357a37.145,37.145,0,0,0,11.908,2.021C842.18,314.458,849.6,307.422,849.6,298.663Z" transform="translate(-778.5 -251.5)" />
        <path :class="foreClass" d="M801.138,327.764v-2.748H792.96v12.911h3v-4.685H800.4v-2.625h-4.441v-2.853h5.182Zm3.841-.123a1.463,1.463,0,1,0-2.926,0A1.463,1.463,0,0,0,804.979,327.641Zm-0.159,10.286v-7.644h-2.626v7.644h2.626Zm7.965-12.876a5.656,5.656,0,0,0-1.321-.158c-2.415,0-3.578,1.479-3.578,3.8v1.586l-1.639,1.691v0.37h1.639v5.583h2.608v-5.583h2.062v-2.061h-2.062v-1.374c0-1.392.511-1.726,1.269-1.726a3.747,3.747,0,0,1,.811.07Zm6.2,7.293v-2.061h-2.027v-2.66l-2.608,1.5v1.163l-1.674,1.691v0.37h1.674V335c0,2.414,1.058,3.065,2.556,3.065a8.622,8.622,0,0,0,1.973-.281l-0.6-1.956a2.57,2.57,0,0,1-.67.124c-0.405,0-.652-0.229-0.652-0.881v-2.73h2.027Zm9.2,5.583v-5.249a2.257,2.257,0,0,0-2.379-2.536,3.715,3.715,0,0,0-2.8,1.462v-6.588H820.4v12.911h2.626v-4.7a2.444,2.444,0,0,1,1.727-.916c0.511,0,.811.229,0.811,0.969v4.65h2.626Zm13.992-10.163v-2.748H834v12.911h3v-4.685h4.441v-2.625H837v-2.853h5.182Zm4.105,10.163V325.016h-2.608v12.911h2.608Zm9.8-3.822a4.118,4.118,0,1,0-4.106,3.963A3.867,3.867,0,0,0,856.082,334.105Zm-2.591,0c0,1.286-.564,2.026-1.515,2.026-0.97,0-1.533-.74-1.533-2.026s0.563-2.026,1.533-2.026C852.927,332.079,853.491,332.819,853.491,334.105Zm11.948,0a4.118,4.118,0,1,0-4.106,3.963A3.867,3.867,0,0,0,865.439,334.105Zm-2.59,0c0,1.286-.564,2.026-1.516,2.026-0.969,0-1.533-.74-1.533-2.026s0.564-2.026,1.533-2.026C862.285,332.079,862.849,332.819,862.849,334.105Zm9.445-1.356v-2.607a2.954,2.954,0,0,0-2.732,1.374v-1.233h-2.555v7.644h2.626V333.1C870.056,332.52,871.043,332.485,872.294,332.749Z" transform="translate(-778.5 -251.5)" />
    </g>
</template>
<script>
    export default {
        props: {
            foreClass: {
                type: String,
                required: false,
                default: ''
            },
            backClass: {
                type: String,
                required: false,
                default: ''
            }
        }
    }
</script>
