<script>
    import Vue from 'vue';
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    library.add(faArrowCircleLeft)

    Vue.component('font-awesome-icon', FontAwesomeIcon)

    export default {
        name: 'PersonDetail',
        props: {
            person: {
                type: Object,
                required: false,
                default() {
                    return {

                    };
                }
            }
        },
        data() {
            return {
                photoIsLoading: true
            }
        },
        created() {

        },
        computed: {
            imgSrc() {
                if (this.person.photo == null)
                    return ''
                else
                    return this.person.photo.desktop;
            },
            addressBlock() {
                if (this.person.officeAddress != null)
                    return this.person.officeAddress.toAddressBlock.replace(/(?:\r\n|\r|\n)/g, '<br/ >');
                else
                    return '';
            },
            photoVisible() {
                return !this.photoIsLoading && !this.person.isPhotoPrivate
            }

        },
        methods: {
            onPhotoLoad() {
                this.photoIsLoading = false
            }
        },
        watch: {
            person() {
                this.photoIsLoading = true;
            }
        }
    }
</script>

<template>
    <div class="row">
        <div class="col-5">
            <img :src="imgSrc" class="headshot-detail"
                 :alt="person.displayName"
                 v-show="photoVisible"
                 @load="onPhotoLoad"/>
        </div>
        <div class="person-details col-7">
            <h1 class="display-4">{{ person.firstName }} {{ person.lastName }}</h1>
            <h2 v-show="person.title != ''"><em>{{ person.title }}</em></h2>
            <p v-html="addressBlock"
               v-show="addressBlock != ''"
               class="address-block">

            </p>
            <p v-show="person.officePhone != ''"
               class="address-block">
                {{ person.officePhone }}
            </p>
            <p v-show="person.email != '' && !person.isEmailPrivate"
               class="address-block">
                {{ person.email }}
            </p>

            <div class="text-end">
                <button @click.stop="$emit('close')"
                        class="btn btn-primary p-3 back-button">
                    <font-awesome-icon :icon="['far', 'arrow-circle-left']"></font-awesome-icon> Back
                </button>
            </div>
        </div>
             
    </div>
</template>
<style>
    .person-details {
        padding:1.5rem 2rem;
    }
    .headshot-detail {
        overflow:hidden;
        max-height:600px;
    }
    .address-block {
        font-size:2rem;
    }
    .back-button {
        font-size:2rem;
    }
</style>