import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Directory from '../views/Directory.vue';
import Map from '../views/Map.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Home,
        name: 'home'
    },
    {
        path: '/directory',
        component: Directory,
        name: 'directory'
    },
    {
        path: '/map',
        component: Map,
        name: 'map'
    },
];


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
