<template>
    <div>
        <div class="btn-group full-width-tabs">
            <a href="#"
               class="btn btn-primary active"
               @click.stop="nameClick"><font-awesome-icon icon="sort-alpha-down" /> By Name</a>
            <a href="#"
               class="btn btn-primary"
               @click.stop="photoClick"><font-awesome-icon :icon="['fal', 'portrait']" /> By Photo</a>
        </div>
        <div class="list-container">
            <name-list v-show="showNameList"
                       :persons="persons"
                       key="namelist"
                       @person-select="detailClick"></name-list>
            <photo-list v-show="showPhotoList"
                        :persons="persons"
                        key="personlist"
                        @person-select="detailClick"></photo-list>
        </div>

        <vue-modal modal-class="modal-xl"
                   v-show="personDetailVisible">
            <person-detail :person="selectedPerson"
                           @close="closeDetail"></person-detail>
        </vue-modal>
    </div>
</template>
<script>

    import Vue from 'vue'

    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faPortrait } from '@fortawesome/pro-light-svg-icons'
    import { faSortAlphaDown } from '@fortawesome/pro-solid-svg-icons'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import * as localExpiryStorage from '@/utils/localStorage';
    import PersonDetail from '../components/PersonDetail.vue';

    library.add(faPortrait)
    library.add(faSortAlphaDown)

    Vue.component('font-awesome-icon', FontAwesomeIcon)

    import NameList from '../components/NameList.vue';
    import PhotoList from '../components/PhotoList.vue';

    const personCacheKey = 'persons_data';
    export default {
        name: 'Directory',
        components: {
            NameList,
            PhotoList,
            PersonDetail
        },
        data() {
            return {
                showNameList: true,
                showPhotoList: false,
                persons: [],
                selectedPersonIndex: 0,
                personDetailVisible: false
            }
        },
        mounted() {
            let persons_data = JSON.parse(localExpiryStorage.getWithExpiry(personCacheKey));

            if (persons_data) {
                // load from local
                this.persons = persons_data
            }
            else {
                // load from API
                this.directoryService.getAll()
                    .then(response => {
                        this.persons = response.data;

                        //store to local
                        localExpiryStorage.setWithExpiry(personCacheKey, JSON.stringify(response.data), 300000)
                    });
            }
            
        },
        computed: {
            selectedPerson() {
                return this.persons[this.selectedPersonIndex];
            }
        },
        methods: {
            closeDetail() {
                this.personDetailVisible = false;
            },
            detailClick(id) {
                this.selectedPersonIndex = this.persons.findIndex(p => p.id == id)

                // use nextTick to avoid seeing the person detail change after becoming visible
                this.$nextTick(() => {
                    this.personDetailVisible = true;
                })
                
            },
            nameClick() {
                this.showPhotoList = false;
                this.showNameList = true;
                
            },
            photoClick() {
                this.showNameList = false;
                this.showPhotoList = true;
            },

        },
        inject: {
            directoryService: {
                type: Object
            }
        }
    }
</script>
<style lang="scss">
   @import '../assets/uw-color-variables.scss';

    /* fixes an issue with the button showing through the modal*/
    .btn-group > .btn,
    .btn-group > .btn.active {
        z-index: auto;
    }

    .btn-group.full-width-tabs {
        width: 100%;
        margin: 1rem 0 0 0;
    }
    .full-width-tabs a {
        width:50%;
        padding:0.8rem;
        font-size:1.7rem;
        text-transform:uppercase;
        font-weight:800;
    }
    .btn-check:active + .btn-primary,
    .btn-check:checked + .btn-primary,
    .btn-primary.active,
    .btn-primary:active,
    .show > .btn-primary.dropdown-toggle {
        color: #fff;
        background-color: $uw-anchor-color;
        border-color: $uw-anchor-color;
    }

    .btn-primary {
        color: #fff;
        background-color: $uw-gray-darker;
        border-color: $uw-gray-darker;
    }

    .btn-primary:hover {
        color: #fff;
        background-color: $uw-gray-darkest;
        border-color: $uw-gray-darkest;
    }
    .table.names {
        background-color: $uw-page-bg;
    }
    .names tr td {
        cursor:pointer;
    }
    .list-container {
        overflow-y: scroll;
        height:740px;
    }
</style>
