import toast from './toast'

function errorResponseHandler(error) {
    // check for errorHandle config
    if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
        return Promise.reject(error);
    }

    // if has response show the error 
    if (error.response) {
        var response = error.response
        var title = response.status + ' ' + response.statusText
        var message = (typeof(response.data.title) !== 'undefined' ? response.data.title : response.data)
        toast.error(message.replace(/(?:\r\n|\r|\n)/g, '<br>'), title);
    }

    // return the reject in case you want additional error processing (but still wanted the standard error)
    return Promise.reject(error);
}

export default errorResponseHandler;