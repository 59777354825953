<template>
    <div class="floor-buttons">
        <a href="#" @click.stop="buttonClick('b')">
            <icon-base view-box="0 0 104 98">
                <basement-button :fore-class="foreClass('b')"
                                 back-class="floor-button-back" />

            </icon-base>
        </a>
        <a href="#" @click.stop="buttonClick('1')">
            <icon-base view-box="0 0 104 98">
                <first-floor-button :fore-class="foreClass('1')"
                                    back-class="floor-button-back" />
            </icon-base>
        </a>
        <a href="#" @click.stop="buttonClick('2')">
            <icon-base view-box="0 0 104 98">
                <second-floor-button :fore-class="foreClass('2')"
                                     back-class="floor-button-back" />
            </icon-base>
        </a>
        <a href="#" @click.stop="buttonClick('3')">
            <icon-base view-box="0 0 104 98">
                <third-floor-button :fore-class="foreClass('3')"
                                    back-class="floor-button-back" />
            </icon-base>
        </a>
        <a href="#" @click.stop="buttonClick('4')">
            <icon-base view-box="0 0 104 98">
                <fourth-floor-button :fore-class="foreClass('4')"
                                     back-class="floor-button-back" />
            </icon-base>
        </a>
        <a href="#" @click.stop="buttonClick('5')">
            <icon-base view-box="0 0 104 98">
                <fifth-floor-button :fore-class="foreClass('5')"
                                    back-class="floor-button-back" />
            </icon-base>
        </a>
    </div>
</template>

<script>
    import Vue from 'vue'
    import IconBase from '@/components/IconBase.vue'
    import BasementButton from '@/components/icons/BasementButton.vue'
    import FirstFloorButton from '@/components/icons/FirstFloorButton.vue'
    import SecondFloorButton from '@/components/icons/SecondFloorButton.vue'
    import ThirdFloorButton from '@/components/icons/ThirdFloorButton.vue'
    import FourthFloorButton from '@/components/icons/FourthFloorButton.vue'
    import FifthFloorButton from '@/components/icons/FifthFloorButton.vue'

    export default {
        components: {
            IconBase,
            BasementButton,
            FirstFloorButton,
            SecondFloorButton,
            ThirdFloorButton,
            FourthFloorButton,
            FifthFloorButton
        },
        props: {
            selectedFloor: {
                type: String,
                required: false,
                default: ''
            }
        },

        methods: {
            buttonClick(floor) {
                this.$emit('floor-click', floor);
            },
            foreClass(floor) {
                return floor == this.selectedFloor ? 'selected ' : 'floor-button-fore';
            }
        }
    }
</script>

<style type="text/css">
    .floor-button-fore {
        fill: #fff;
    }

    .floor-button-back, floor-button-fore {
        fill-rule: evenodd;
    }

    .floor-button-back {
        fill: #c5050c;
        stroke: #fff;
        stroke-linejoin: round;
        stroke-width: 3px;
    }
    .floor-buttons a {
        padding:0 1.3rem;
    }
    .selected {
        fill: #258faf;
    }
</style>