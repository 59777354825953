<template>
    <div>
        <img src="../assets/20100609_4118.jpg" />


    </div>
</template>
<script>
    export default {
        name: 'Home',
        props: {

        },
        components: {
        },
        data() {
            return {

            }
        },
        created() {

        },
        computed: {

        },
        methods: {

        }
    }
</script>

