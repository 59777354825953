<template>
    <g>
        <path :class="backClass" d="M177.053,255h96.188v91H177.053V255Z" transform="translate(-173.5 -251.5)" />
        <path :class="foreClass" d="M231.852,313.71V268.57h-3.52l-18.65,7.186,3.521,9.283,7.564-3.144V313.71h11.085Z" transform="translate(-173.5 -251.5)" />
        <path :class="foreClass" d="M196.311,327.03v-2.748h-8.178v12.912h3v-4.686h4.442v-2.624h-4.442V327.03h5.182Zm3.841-.123a1.463,1.463,0,1,0-2.926,0A1.463,1.463,0,0,0,200.152,326.907Zm-0.159,10.287v-7.645h-2.626v7.645h2.626Zm7.384-5.179v-2.607a2.954,2.954,0,0,0-2.732,1.374v-1.233H202.09v7.645h2.626v-4.827C205.139,331.786,206.126,331.751,207.377,332.015Zm7.26,2.871c0-2.73-3.859-2.131-3.859-3.1,0-.264.282-0.476,0.793-0.476a5.143,5.143,0,0,1,2.273.687l0.635-1.867a6.405,6.405,0,0,0-2.785-.722c-1.868,0-3.243,1.022-3.243,2.572,0,2.554,3.719,1.972,3.719,2.924,0,0.3-.264.51-0.881,0.51a4.836,4.836,0,0,1-2.485-.933l-0.828,1.867a6.067,6.067,0,0,0,3.313.986C213.174,337.334,214.637,336.4,214.637,334.886Zm6.714-3.276v-2.061h-2.027v-2.66l-2.608,1.5v1.163l-1.674,1.691v0.37h1.674v2.66c0,2.413,1.058,3.064,2.556,3.064a8.622,8.622,0,0,0,1.973-.281l-0.6-1.956a2.571,2.571,0,0,1-.669.124c-0.406,0-.653-0.229-0.653-0.881v-2.73h2.027Zm13.6-4.58v-2.748h-8.177v12.912h3v-4.686h4.441v-2.624h-4.441V327.03h5.181Zm4.106,10.164V324.282h-2.609v12.912h2.609Zm9.8-3.823a4.118,4.118,0,1,0-4.106,3.963A3.867,3.867,0,0,0,248.859,333.371Zm-2.591,0c0,1.286-.564,2.026-1.515,2.026-0.97,0-1.534-.74-1.534-2.026s0.564-2.025,1.534-2.025C245.7,331.346,246.268,332.085,246.268,333.371Zm11.948,0a4.118,4.118,0,1,0-4.106,3.963A3.867,3.867,0,0,0,258.216,333.371Zm-2.591,0c0,1.286-.564,2.026-1.515,2.026-0.97,0-1.534-.74-1.534-2.026s0.564-2.025,1.534-2.025C255.061,331.346,255.625,332.085,255.625,333.371Zm9.446-1.356v-2.607a2.954,2.954,0,0,0-2.732,1.374v-1.233h-2.555v7.645h2.625v-4.827C262.832,331.786,263.819,331.751,265.071,332.015Z" transform="translate(-173.5 -251.5)" />
    </g>
</template>
<script>
    export default {
        props: {
            foreClass: {
                type: String,
                required: false,
                default: ''
            },
            backClass: {
                type: String,
                required: false,
                default: ''
            }
        }
    }
</script>
